import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import { json } from '@remix-run/node';
import { authenticate } from './shopify.server';

export const loader = async ({ request }) => {
  const url = new URL(request.url);

  // Skip authentication for auth routes
  if (url.pathname.startsWith('/auth')) {
    return json({
      userId: null,
      shop: null,
      STATSIG_CLIENT_KEY: process.env.STATSIG_CLIENT_KEY,
    });
  }

  const { admin, session } = await authenticate.admin(request);
  let userId;
  try {
    const response = await admin.graphql(
      `query {
        currentAppInstallation {
          id
        }
      }`
    );
    const responseJson = await response.json();
    userId = responseJson.data.currentAppInstallation.id;
  } catch (error) {
    console.error('Error fetching user ID:', error);
    userId = 'unknown';
  }

  return json({
    userId,
    shop: session.shop,
    STATSIG_CLIENT_KEY: process.env.STATSIG_CLIENT_KEY,
  });
};

export default function App() {
  const { userId, shop, STATSIG_CLIENT_KEY } = useLoaderData();
  const statsigOptions = {
    environment: { tier: process.env.NODE_ENV },
    user:
      userId && shop
        ? {
            // Add conditional check for statsig user data
            userID: userId,
            customIDs: { tenant: shop },
          }
        : null,
  };

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
